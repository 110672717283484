import React, { useState } from "react"
export const myContext = React.createContext("")

const Provider = ({ children }) => {
  const [activeLink, setActiveLink] = useState("")

  return (
    <myContext.Provider
      value={{
        activeLink,
        setActiveLink,
      }}
    >
      {children}
    </myContext.Provider>
  )
}

const Consumer = ({ element }) => <Provider>{element}</Provider>
export default Consumer
